import React, { useState } from 'react'
import logo from '../assets/sportsnap.png'
import { FiShoppingCart } from "react-icons/fi";
import { IoIosSearch } from "react-icons/io";
import { IoMenu } from "react-icons/io5";
import { FaRegCircleUser } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";
import Avtaar from 'react-avatar'


const Navbar = () => {

  const [menu, setMenu] = useState(false);

  return (
    <>

      <div className='bg-gray-900 w-full h-[65px]  text-white p-3 flex items-center justify-between'>

        <div className='flex items-center '>
          <IoMenu onClick={() => setMenu(!menu)} className='text-2xl mb-2 border border-gray-400 rounded p-[2px] text-gray-400 cursor-pointer' />
          <img src={logo} alt="" className='h-[47px] w-[auto] p-1 cursor-pointer ' />
        </div>

        {/* <div className='hidden md:flex w-3/6 border-4 rounded-md border-yellow-500'>
          <input type="text" placeholder='Search here..' className='w-full h-[40px] p-4   outline-none text-black' />
          <button className='bg-yellow-500  h-[40px] px-2 text-black text-3xl '><IoIosSearch /></button>
        </div> */}

        <div>
          <Avtaar name='Ayush rajput' size='40' round='50%' className='cursor-pointer'></Avtaar>
        </div>



      </div>

      <div className='bg-gray-800 w-full h-[60px] md:hidden text-white p-3 flex items-center '>


        <div className='flex w-5/6  mx-auto border-4 rounded-md border-yellow-500'>
          <input type="text" placeholder='Search here..' className='w-full h-[40px] p-4  outline-none text-black' />
          <button className=' bg-yellow-500 h-[40px] px-2 text-black text-3xl'><IoIosSearch /></button>
        </div>

      </div>

      {
        menu &&
        <div>
          <div onClick={() => setMenu(!menu)} className={` fixed  top-0 left-0 bg-gray-800 opacity-80 h-screen w-full side-left transition-all duration-500`}></div>

          <div className='flex gap-2 h-full w-3/6 min-w-[300px] max-w-[380px] absolute top-0'>

            <div className='bg-white h-full w-5/6'>

              <div className='w-full bg-gray-800 h-[55px] text-white p-4 flex items-center gap-3 cursor-pointer'>
                <img src={logo} alt="" className='h-[47px] w-[auto] p-1 cursor-pointer ' />
              </div>

            </div>

            <div>

              <button onClick={() => setMenu(!menu)} className='border-2 p-1  mt-2 text-2xl text-white bg-gray-900 rounded '><RxCross2 /></button>

            </div>
          </div>
        </div>



      }

    </>
  )
}

export default Navbar