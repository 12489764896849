import React from 'react'
import Navbar from '../components/Navbar'

const Home = () => {


  return (
    <div className='h-screen'>
      <Navbar />
      <div className='flex flex-col items-center h-full'>
        <h1>Welcome to Sport-Snap</h1>
        <p>this project is under process not ready yet.</p>
        <p> read more on <a className="text-blue-500" href='https://jitendra-ky.notion.site/Team-BlueSky-137a004eae7e8035b584ea9b8e95a9f6' target="_blank" rel="noopener noreferrer">jitendra-ky.notion.site/Team-BlueSky</a></p>
        <div className="prototype">
          <h3>check the DEMO   live on </h3>
          <a className="text-blue-500"  href="https://sportsnap.azurewebsites.net">sportsnap.azurewebsites.net</a>
        </div>
        <div className="video-container mt-5">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/5--Abjhkd8E"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  )
}

export default Home